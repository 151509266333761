import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react'

type DisplayIngredientRowProps = {
    ingredient: string
}
type DisplayIngredientRowState = {
    checked: boolean
}
class DisplayIngredientRow extends React.Component<DisplayIngredientRowProps, DisplayIngredientRowState>{

    constructor(props: DisplayIngredientRowProps) {
        super(props)
        this.state = {
            checked: false
        }
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
        this.handleListItemClick = this.handleListItemClick.bind(this)
    }

    handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            checked: event.target.checked
        })
    }

    handleListItemClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.setState(prevState => {
            return { checked: !prevState.checked }
        })
    }

    render() {

        let { ingredient } = this.props
        let { checked } = this.state

        if (checked) {
            return (
                <ListItem
                    button
                    onClick={this.handleListItemClick}
                    selected={checked}>
                    <ListItemIcon>
                        <Checkbox checked={checked} onChange={this.handleCheckboxChange} />
                    </ListItemIcon>
                    <ListItemText secondary>
                        {ingredient}
                    </ListItemText>
                </ListItem>
            );
        }

        return (
            <ListItem
                button
                onClick={this.handleListItemClick}
                selected={checked}>
                <ListItemIcon>
                    <Checkbox checked={checked} onChange={this.handleCheckboxChange} />
                </ListItemIcon>
                <ListItemText>
                    {ingredient}
                </ListItemText>
            </ListItem>);
    }
}

export default DisplayIngredientRow