import { Avatar, Card, CardActionArea, CardContent, CardHeader, Typography, withStyles } from '@material-ui/core'
import { red, grey } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import React from 'react'
import Recipe from './Recipe'

const styles = (theme: any) => ({
    card: {
        maxWidth: 345,
        margin: theme.spacing(2),
    },
    modal: {

        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: grey[50],
        outline: 0
    },
    root: {
        maxWidth: 345,
        padding: theme.spacing(2),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
        background: red[500],
    },
})

type RecipeProps = {
    recipe: Recipe,
    classes?: any
}
type RecipeState = {
    recipe: Recipe,
    open: boolean
}
class RecipeCard extends React.Component<RecipeProps, RecipeState> {

    constructor(props: RecipeProps) {
        super(props)
        this.state = {
            recipe: props.recipe,
            open: false
        }
    }

    render() {
        const { classes } = this.props
        const recipe = this.state.recipe
        return (
            <Link to={`/recipe/${this.state.recipe.path}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardHeader
                            avatar={
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    {recipe.name[0]}
                                </Avatar>
                            }
                            title={recipe.name}
                            subheader="September 14, 2016"
                        />

                        <CardContent>
                            <Typography variant="body1" color="textPrimary" component="p">
                                {recipe.description}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>
        )
    }
}

export default withStyles(styles, { withTheme: true })(RecipeCard)