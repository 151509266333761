import { TextField } from '@material-ui/core'
import React from 'react'

type InstructionRowProps = {
    instruction: string,
    index: number,
    updateInstruction: (index: number, newValue: string) => void
}
type InstructionRowState = {

}
class EditInstructionRow extends React.Component<InstructionRowProps, InstructionRowState> {

    constructor(props: InstructionRowProps) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e: any) {
        this.props.updateInstruction(this.props.index, e.target.value)
    }

    render() {
        const { instruction } = this.props
        return (
            <TextField
                label='Instruction'
                multiline
                defaultValue={instruction}
                variant="standard"
                fullWidth
                onChange={this.onChange}
            />
        )
    }
}
export default EditInstructionRow