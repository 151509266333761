
import { pink, red } from "@material-ui/core/colors";
import { createMuiTheme } from '@material-ui/core';

const Theme = createMuiTheme({
    "palette": {
        "primary": { main: red[500] },
        "secondary": { main: pink[400] }
    }
})

export default Theme