import { withWidth } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react'
import Recipe from '../Recipe'
import recipeApi from '../../../api/recipe/axios'
import History from '../../../utils/history'
import DisplayRecipePage from './display/DisplayRecipePage';
import EditRecipePage from './edit/EditRecipePage';

type RouteParams = {
    path: string,
}
type RecipePageProps = RouteComponentProps<RouteParams, any, any> & {
    isCreate?: boolean
}
type RecipePageState = {
    error: string | null,
    isLoaded: boolean,
    recipe: Recipe | null,
    editedRecipe: Recipe | null,
    isEditing: boolean,
}
class RecipePage extends React.Component<RecipePageProps, RecipePageState> {

    static defaultProps = {
        isCreate: false
    }

    constructor(props: RecipePageProps) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            recipe: null,
            editedRecipe: null,
            isEditing: props.isCreate ? props.isCreate : false
        }
        this.startEditing = this.startEditing.bind(this)
        this.stopEditing = this.stopEditing.bind(this)
        this.saveRecipe = this.saveRecipe.bind(this)
    }

    startEditing() {
        if (this.state.recipe) {
            this.setState({
                isEditing: true,
                editedRecipe: JSON.parse(JSON.stringify(this.state.recipe))
            })
        }
    }

    stopEditing() {
        this.setState({
            isEditing: false,
        })
    }

    async saveRecipe() {
        this.setState({ isEditing: false })
        if (this.props.isCreate) {
            try {
                const response = await recipeApi.post(`/recipe`, this.state.editedRecipe, { withCredentials: true })
                console.log(response)
                this.setState({
                    isLoaded: true,
                    recipe: response.data,
                })
                History.push(`/recipe/${response.data.path}`)
            }
            catch (err) {
                // TODO route to 404
                console.log(err)
                this.setState({
                    isLoaded: true,
                    error: err
                })
            }
        } else {
            try {
                const response = await recipeApi.put(`/recipe/${this.state.recipe?.path}`, this.state.editedRecipe, { withCredentials: true })
                console.log(response)
                this.setState({
                    isLoaded: true,
                    recipe: response.data,
                })
                History.push(`/recipe/${response.data.path}`)
            }
            catch (err) {
                // TODO route to 404
                console.log(err)
                this.setState({
                    isLoaded: true,
                    error: err
                })
            }
        }

    }

    async componentDidMount() {
        if (this.props.isCreate) {
            const emptyRecipe = {
                id: '',
                name: '',
                path: '',
                description: '',
                ingredients: [""],
                instructions: [""],
                yield: 0,
                cookTime: 0,
                cookType: ''
            }
            this.setState({
                isLoaded: true,
                recipe: emptyRecipe,
                editedRecipe: emptyRecipe
            })
        }
        else {
            const path = this.props.match.params.path;
            try {
                const response = await recipeApi.get(`/recipe/${path}`, { withCredentials: true })
                this.setState({
                    isLoaded: true,
                    recipe: response.data,
                })
            }
            catch (err) {
                // TODO route to 404
                console.log(err)
                this.setState({
                    isLoaded: true,
                    error: err
                })
            }
        }
    }

    render() {
        const { error, isLoaded, recipe, editedRecipe, isEditing } = this.state;
        if (error) {
            return <div>Error: {error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (!recipe) {
            return <div>Error, recipe is null</div>;
        } else if (isEditing) {
            return (<EditRecipePage recipe={editedRecipe!} saveRecipe={this.saveRecipe} stopEditing={this.stopEditing} />)
        } else {
            return (<DisplayRecipePage recipe={recipe!} editRecipe={this.startEditing} />)
        }
    }
}

export default withWidth()(withRouter(RecipePage))