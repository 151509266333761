import { TextField } from '@material-ui/core'
import React from 'react'

type IngredientRowProps = {
    ingredient: string,
    index: number,
    updateIngredient: (index: number, newValue: string) => void
}
type IngredientRowState = {

}
class EditIngredientRow extends React.Component<IngredientRowProps, IngredientRowState> {

    constructor(props: IngredientRowProps) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
    }

    onChange(e: any) {
        this.props.updateIngredient(this.props.index, e.target.value)
    }

    onKeyUp(e: React.KeyboardEvent) {
        if (e.key === "Enter" && this.props.ingredient.length > 0) {
            // TBD
        }
    }

    render() {
        const { ingredient } = this.props
        return (
            <TextField
                label='Ingredient'
                defaultValue={ingredient}
                variant="standard"
                fullWidth
                onChange={this.onChange}
                onKeyUp={this.onKeyUp}
            />
        )
    }
}
export default EditIngredientRow