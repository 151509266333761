import { Button, Divider, Grid, Hidden, IconButton, ImageListItem, List, ListItem, ListItemSecondaryAction, TextField, Typography } from '@material-ui/core';
import { Add, Cancel, Clear, Done } from '@material-ui/icons';
import React from 'react'
import Recipe from '../../Recipe';
import EditIngredientRow from './EditIngredientRow';
import EditInstructionRow from './EditInstructionRow';

type KeyedString = {
    id: string
    text: string
}
type EditRecipePageProps = {
    isCreate?: boolean
    recipe: Recipe
    saveRecipe: () => void
    stopEditing: () => void
}
type EditRecipePageState = {
    isLoaded: boolean
    recipe: Recipe | null
    keyedIngredients: KeyedString[]
    keyedInstructions: KeyedString[]
}
class EditRecipePage extends React.Component<EditRecipePageProps, EditRecipePageState> {

    constructor(props: EditRecipePageProps) {
        super(props)

        this.state = {
            isLoaded: false,
            recipe: this.props.recipe,
            keyedIngredients: [],
            keyedInstructions: []
        }

        this.addIngredient = this.addIngredient.bind(this)
        this.addInstruction = this.addIngredient.bind(this)
        this.saveRecipe = this.saveRecipe.bind(this)
        this.stopEditing = this.stopEditing.bind(this)
        this.removeIngredient = this.removeIngredient.bind(this)
        this.removeInstruction = this.removeInstruction.bind(this)
        this.updateIngredient = this.updateIngredient.bind(this)
        this.updateInstruction = this.updateInstruction.bind(this)
        this.updateName = this.updateName.bind(this)
        this.updateDescription = this.updateDescription.bind(this)
    }

    async componentDidMount() {
        if (this.props.isCreate) {
            this.setState({
                isLoaded: true,
                recipe: this.props.recipe,
                keyedIngredients: [],
                keyedInstructions: []
            })
        }
        else {
            this.setState({
                isLoaded: true,
                recipe: this.props.recipe,
                keyedIngredients: this.props.recipe.ingredients.reduce(function (list: KeyedString[], ingredient: string, index: number) {
                    list.push({
                        id: index.toString(),
                        text: ingredient,
                    })
                    return list
                }, []),
                keyedInstructions: this.props.recipe.instructions.reduce(function (list: KeyedString[], instruction: string, index: number) {

                    list.push({
                        id: index.toString(),
                        text: instruction,
                    })
                    return list
                }, [])
            })
        }
    }

    updateName(e: any) {
        this.setState(prevState => {
            const recipe = prevState.recipe!;
            recipe.name = e.target.value

            return {
                recipe: recipe
            };
        })
    }

    updateDescription(e: any) {
        this.setState(prevState => {
            const recipe = prevState.recipe!;
            recipe.description = e.target.value

            return {
                recipe: recipe
            };
        })
    }

    saveRecipe() {
        this.props.saveRecipe()
    }

    stopEditing() {
        this.props.stopEditing()
    }

    addInstruction() {
        this.setState(prevState => {
            if (prevState.recipe) {

                const newInstructions = prevState.keyedInstructions;
                const newRecipe = prevState.recipe;
                newInstructions.push({
                    id: newInstructions.length.toString(),
                    text: ""
                })
                newRecipe.instructions.push("")

                return {
                    recipe: newRecipe,
                    keyedInstructions: newInstructions
                };
            }
            return {
                recipe: null,
                keyedInstructions: []
            }
        })
    }

    addIngredient() {
        this.setState(prevState => {
            if (prevState.recipe) {
                const newIngredients = prevState.keyedIngredients;
                const newRecipe = prevState.recipe;

                newIngredients.push({
                    id: newIngredients.length.toString(),
                    text: ""
                })
                newRecipe.ingredients.push("")
                return { recipe: newRecipe };
            }
            return { recipe: null }
        })
    }

    removeInstruction(indexToRemove: number) {
        this.setState(prevState => {
            const newRecipe = prevState.recipe!;

            newRecipe.instructions.splice(indexToRemove, 1)
            const newInstructions = prevState.keyedInstructions.filter((instruction, index, array) => { return index !== indexToRemove });
            return {
                recipe: newRecipe,
                keyedInstructions: newInstructions
            };
        })
    }

    removeIngredient(indexToRemove: number) {
        this.setState(prevState => {
            const newRecipe = prevState.recipe!;

            newRecipe.ingredients.splice(indexToRemove, 1)
            const newIngredients = prevState.keyedIngredients.filter((ingredient, index, array) => { return index !== indexToRemove });
            return {
                recipe: newRecipe,
                keyedIngredients: newIngredients
            };
        })
    }

    updateIngredient(index: number, newValue: string) {
        this.setState(prevState => {
            const ingredients = prevState.keyedIngredients;
            const recipe = prevState.recipe!;

            ingredients[index].text = newValue
            recipe.ingredients[index] = newValue
            return {
                recipe: recipe,
                keyedIngredients: ingredients
            };
        })
    }

    updateInstruction(index: number, newValue: string) {

        this.setState(prevState => {
            const recipe = prevState.recipe!
            recipe.instructions[index] = newValue

            const keyedInstructions = prevState.keyedInstructions
            keyedInstructions[index].text = newValue
            return {
                recipe: recipe,
                keyedInstructions: keyedInstructions
            }
        })
    }

    render() {
        let { isLoaded, recipe, keyedIngredients, keyedInstructions } = this.state
        if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <Grid container spacing={1}>

                    <Grid item xs={12} md={1}>

                        <Hidden mdDown>
                            <List>
                                <ListItem>
                                    <Button startIcon={<Done />} variant="outlined" onClick={this.saveRecipe}>Save</Button>
                                </ListItem>
                                <ListItem>
                                    <Button startIcon={<Cancel />} variant="outlined" onClick={this.stopEditing}>Cancel</Button>
                                </ListItem>
                            </List>
                        </Hidden>
                        <Hidden mdUp>
                            <Grid item container spacing={1} xs={12}>

                                <Grid item xs={6}>
                                    <Button startIcon={<Done />} variant="outlined" onClick={this.saveRecipe}>Save</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button startIcon={<Cancel />} variant="outlined" onClick={this.stopEditing}>Cancel</Button>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>

                        <List sx={{ width: '100%' }}>
                            <ListItem>
                                <TextField
                                    InputProps={{ style: { fontSize: 46 } }}
                                    multiline
                                    defaultValue={recipe!.name}
                                    label='Name'
                                    InputLabelProps={{ style: { fontSize: 25 } }}
                                    variant="standard"
                                    fullWidth
                                    onChange={this.updateName} />
                            </ListItem>
                            <ImageListItem>
                                <img
                                    src={`${process.env.PUBLIC_URL}/foodPlaceHolder.png`}
                                    alt="Food"
                                    loading="lazy"
                                />
                            </ImageListItem>
                            <ListItem>
                                <TextField
                                    multiline
                                    defaultValue={recipe!.description}
                                    variant="standard"
                                    label='Description'
                                    fullWidth
                                    onChange={this.updateDescription}
                                />
                            </ListItem>

                        </List>

                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <List sx={{ width: '100%' }}>
                            <ListItem>
                                <Typography variant='h4'>
                                    Ingredients
                                </Typography>
                            </ListItem>

                            {keyedIngredients.map((ingredient, index) => {
                                return (
                                    <ListItem key={ingredient.id}>
                                        <EditIngredientRow ingredient={ingredient.text} index={index} updateIngredient={this.updateIngredient} />
                                        <ListItemSecondaryAction>
                                            <IconButton color="primary" onClick={() => this.removeIngredient(index)}>
                                                <Clear fontSize="small" />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}


                            <ListItem>
                                <Button fullWidth variant="outlined" onClick={() => this.addIngredient()}>
                                    <Add fontSize="medium" />
                                </Button>
                            </ListItem>
                        </List>
                    </Grid>

                    <Hidden mdDown>
                        <Divider variant="inset" orientation="vertical" flexItem />
                    </Hidden>

                    <Grid item xs={12} md={6} lg sx={{ justifyContent: 'center' }} >
                        <List>
                            <ListItem alignItems="flex-start">
                                <Typography variant='h4'>
                                    Instructions
                                </Typography>
                            </ListItem>
                            {keyedInstructions.map((instruction, index) => {
                                return (
                                    <ListItem key={instruction.id} alignItems="flex-start">
                                        <EditInstructionRow instruction={instruction.text} index={index} updateInstruction={this.updateInstruction} />
                                        <ListItemSecondaryAction>
                                            <IconButton color="primary" onClick={() => this.removeInstruction(index)}>
                                                <Clear fontSize="small" />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}

                            <ListItem>
                                <Button fullWidth variant="outlined" onClick={() => this.addInstruction()}>
                                    <Add fontSize="large" />
                                </Button>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid >
            )
        }
    }
}

export default EditRecipePage