import { Alert, Button, Grid, Input, InputLabel, Snackbar, withStyles } from "@material-ui/core";
import History from '../../utils/history'
import React from "react";
import recipeApi from '../../api/recipe/axios';

const styles = (theme: any) => ({
    loginForm: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        align: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    alert: {
        width: '90%',
        paddingTop: theme.spacing(8),
    },
});


type LoginProps = {
    classes: any
}
type LoginState = {
    username: string,
    password: string,
    showError: boolean,
    error: string
}
class Login extends React.Component<LoginProps, LoginState> {

    constructor(props: LoginProps) {
        super(props)
        this.state = {
            username: '',
            password: '',
            showError: false,
            error: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeError = this.closeError.bind(this)
    }

    closeError() {
        this.setState({
            showError: false
        })
    }

    async handleSubmit(e: any) {
        e.preventDefault()
        try {
            localStorage.clear()
            var response = await recipeApi.post('/login', `username=${this.state.username}&password=${this.state.password}`)
            console.log(response)
            if (response.status === 200) {
                localStorage.setItem('access_token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                History.goBack()
            }
        }
        catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                this.setState({
                    error: "Incorrect username or password",
                    showError: true
                })
            }
            if (error.response.status === 400) {
                this.setState({
                    error: "Please enter a valid username and password",
                    showError: true
                })
            }
            console.log(error)
        }
    }

    render() {
        const { classes } = this.props
        const { showError, error } = this.state
        return (
            <Grid className={classes.loginForm}>
                <Snackbar open={showError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={this.closeError} className={classes.alert}>
                    <Alert onClose={this.closeError} severity="error" className={classes.alert}>
                        {error}
                    </Alert>
                </Snackbar>
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <InputLabel htmlFor="username">Username</InputLabel>
                        <Input id="username" type="text"
                            onChange={(e) => this.setState({
                                username: e.target.value
                            })} />
                    </div>
                    <div>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            id="password"
                            type="password"
                            value={this.state.password}
                            onChange={(e) => this.setState({
                                password: e.target.value
                            })}
                        />
                    </div>
                    <Button type="submit">Login</Button>
                </form>
            </Grid >
        )
    }
}

export default withStyles(styles)(Login)