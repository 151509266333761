import React from 'react'
import {
    Router,
    Switch,
    Route,
} from "react-router-dom";
import history from '../utils/history'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { Logout, RestaurantOutlined } from '@material-ui/icons';
import { Button, Container, Link, Typography, withStyles } from '@material-ui/core';
import RecipeList from './recipe/RecipeList';
import Login from './auth/Login';
import RecipePage from './recipe/single/RecipePage';

const styles = (theme: any) => ({
    appBar: {
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    title: {
        paddingRight: theme.spacing(8)
    },
    titleLink: {
        flexGrow: 1
    },
    recipeGrid: {
        align: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
});

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">Guoba Eats</Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

type MainPageProps = {
    classes: any
};
type MainPageState = {
};
class MainPage extends React.Component<MainPageProps, MainPageState> {

    handleLogout() {
        localStorage.clear()
        console.log('logged out!')
        if (history.location.pathname === '/') {
            window.location.reload()
        } else {
            history.push('/')
        }
    }

    render() {
        const { classes } = this.props
        return (

            <React.Fragment>
                <CssBaseline />

                <AppBar color="primary" className={classes.appBar} position="relative">
                    <Toolbar>
                        <RestaurantOutlined className={classes.icon} />
                        <Typography variant="h5" color="inherit" className={classes.title} component="div">
                            Guoba Eats
                        </Typography>
                        <Link href="/" style={{ color: 'inherit', textDecoration: 'inherit' }} className={classes.titleLink}>
                            <Typography variant="h6" color="inherit" component="div">
                                Recipes
                            </Typography>
                        </Link>

                        <Button color="inherit" onClick={this.handleLogout}>
                            <Logout />
                        </Button>
                    </Toolbar>
                </AppBar>

                <main>
                    <Container className={classes.recipeGrid} maxWidth="xl">
                        <Router history={history}>
                            <Switch>
                                <Route exact path="/">
                                    <RecipeList />
                                </Route>
                                <Route exact path="/login">
                                    <Login />
                                </Route>
                                <Route path="/recipe/new">
                                    <RecipePage isCreate={true} />
                                </Route>
                                <Route path="/recipe/:path">
                                    <RecipePage />
                                </Route>
                            </Switch>
                        </Router>
                    </Container>
                </main>

                <footer >
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="textSecondary"
                        component="p"
                    >
                        You've seen the dragon in Mondstadt? How big was it? What!? No way! Imagine how big a steak a dragon that huge would make!
                    </Typography>
                    <Copyright />
                </footer>
            </React.Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MainPage)