import { AxiosInstance } from 'axios';
import History from '../../utils/history'
import axios from 'axios';

const env = process.env.NODE_ENV || 'development';
const apiUrl = env === 'production' ? 'https://guoba.azurewebsites.net' : 'http://localhost:8080'

const recipeApi: AxiosInstance = axios.create({
    baseURL: apiUrl
});

async function refreshAccessToken() {

    try {
        const response = await recipeApi.post('/oauth/access_token', {
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem('refresh_token')
        })
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        return response.data.access_token;
    }
    catch (err) {
        return null
    }
}

// Request interceptor for API calls
recipeApi.interceptors.request.use(
    async (config: any) => {
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            config.headers = {
                'Authorization': `Bearer ${access_token}`,
                'Accept': 'application/json',
            }
        }
        return config;
    },
    (error: any) => {
        Promise.reject(error)
    });

// Response interceptor for API calls
recipeApi.interceptors.response.use(
    (response: any) => {
        return response
    },
    async function (error: any) {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry && localStorage.getItem('refresh_token')) {
            originalRequest._retry = true;
            const access_token = await refreshAccessToken();
            if (access_token) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
                return recipeApi(originalRequest);
            }
        } else if (error.response.status === 400 && originalRequest.url.endsWith('/oauth/access_token')) {
            localStorage.clear();
            History.push('/login')
        } else if (error.response.status === 401) {
            localStorage.clear();
            History.push('/login')
        }
        return Promise.reject(error);
    });

export default recipeApi