import React from 'react';
import './RecipeList.css';
import recipeApi from '../../api/recipe/axios';
import RecipeCard from './RecipeCard';
import Grid from '@material-ui/core/Grid';
import { Alert, Button, withStyles } from '@material-ui/core';
import { Add, Search, SearchOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom'
import { InstantSearch, SearchBox, Hits, connectHits } from 'react-instantsearch-dom';
import searchClient from '../../utils/searchClient';
import Recipe from './Recipe';

const styles = (theme: any) => ({
  itemGrid: {
    justifyContent: 'center',
  },
  alert: {
    display: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(8),
  },
});

type RecipesProps = {
  classes: any
};
type RecipesState = {
  showError: boolean,
  error: string,
  isLoaded: boolean,
  recipes: any[]
};
class RecipeList extends React.Component<RecipesProps, RecipesState> {

  constructor(props: RecipesProps) {
    super(props);
    this.state = {
      showError: false,
      error: '',
      isLoaded: false,
      recipes: []
    };
    this.closeError = this.closeError.bind(this)
  }

  closeError() {
    this.setState({
      showError: false
    })
  }

  async componentDidMount() {

    try {
      const response = await recipeApi.get('/recipe', { withCredentials: true })
      this.setState({
        isLoaded: true,
        recipes: response.data,
        showError: false
      })
    }
    catch (error) {
      if (!error.response) {
        this.setState({
          isLoaded: true,
          recipes: [],
          error: "Backend is unavailable",
          showError: true
        })
      }
      else {
        this.setState({
          isLoaded: true,
          recipes: [],
          error: error,
          showError: true
        })
      }
    }
  }

  render() {
    const { classes } = this.props
    const { showError, error, isLoaded, recipes } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    }
    else if (showError) {
      return (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
      )
    } else {
      const Hits = (hits) => {
        if (hits) {
          return (hits.map((hit) => (<p>{hit.name}</p>)))
        }
        return "empty"
        // <Grid key={recipe.id} item xs={12} md={8} lg={3} xl={2}>
        //   <RecipeCard recipe={recipe}></RecipeCard>
        // </Grid>
      }

      const CustomHits = connectHits(Hits);
      return (

        <Grid container spacing={2} className={classes.itemGrid} >

          <InstantSearch indexName="dev_guoba_eats"
            searchClient={searchClient}
          >
            <Grid className={classes.itemGrid} item xs={12} style={{ margin: 15 }}>

              <SearchBox />
            </Grid>

            <Grid className={classes.itemGrid} item xs={12} style={{ margin: 15 }}>
              <Link to='/recipe/new'>
                <Button fullWidth variant="outlined"><Add /></Button>
              </Link>
            </Grid>
            <CustomHits />
            {
              recipes.map(recipe => (
                <Grid key={recipe.id} item xs={12} md={8} lg={3} xl={2}>
                  <RecipeCard recipe={recipe}></RecipeCard>
                </Grid>
              ))
            }

          </InstantSearch>
        </Grid >
      );
    }
  }
}
export default withStyles(styles)(RecipeList);
