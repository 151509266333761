import { Button, Divider, Grid, Hidden, ImageListItem, List, ListItem, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'
import Recipe from '../../Recipe';
import DisplayIngredientRow from './DisplayIngredientRow';
import DisplayInstructionRow from './DisplayInstructionsRow';

type DisplayRecipePageProps = {
    recipe: Recipe
    editRecipe: () => void
}
type DisplayRecipePageState = {
    isLoaded: boolean
    recipe: Recipe | null
}
class DisplayRecipePage extends React.Component<DisplayRecipePageProps, DisplayRecipePageState> {

    constructor(props: DisplayRecipePageProps) {
        super(props)
        this.state = {
            isLoaded: false,
            recipe: null
        }
        this.startEditing = this.startEditing.bind(this)
    }

    componentDidMount() {
        this.setState({
            isLoaded: true,
            recipe: this.props.recipe
        })
    }


    startEditing() {
        this.props.editRecipe()
    }

    render() {
        let { isLoaded, recipe } = this.state
        if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={1}>
                        <Hidden mdDown>
                            <List>
                                <ListItem>
                                    <Button startIcon={<Edit />} variant="outlined" onClick={this.startEditing}>Edit</Button>
                                </ListItem>
                            </List>
                        </Hidden>
                        <Hidden mdUp>
                            <Button startIcon={<Edit />} variant="outlined" onClick={this.startEditing}>Edit</Button>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <List sx={{ width: '100%' }}>
                            <ListItem>
                                <Typography variant='h3'>
                                    {recipe!.name}
                                </Typography>
                            </ListItem>

                            <ImageListItem>
                                <img
                                    src={`${process.env.PUBLIC_URL}/foodPlaceHolder.png`}
                                    alt="Food"
                                    loading="lazy"
                                />
                            </ImageListItem>
                            <ListItem >
                                <Typography variant='body1' component="div">
                                    {recipe!.description}
                                </Typography>
                            </ListItem>
                        </List>

                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <List sx={{ width: '100%' }}>
                            <ListItem alignItems="flex-start">
                                <Typography variant='h4'>
                                    Ingredients
                                </Typography>
                            </ListItem>
                            {recipe!.ingredients.map((ingredient) => {
                                return (<DisplayIngredientRow ingredient={ingredient} key={ingredient} />);
                            })}
                        </List>
                    </Grid>

                    <Hidden mdDown>
                        <Divider variant="inset" orientation="vertical" flexItem />
                    </Hidden>

                    <Grid item xs={12} md={6} lg sx={{ justifyContent: 'center', margin: '200' }} >
                        <List>
                            <ListItem alignItems="flex-start">
                                <Typography variant='h4'>
                                    Instructions
                                </Typography>
                            </ListItem>
                            {recipe!.instructions.map(instruction => {
                                return (<DisplayInstructionRow instruction={instruction} key={instruction} />)
                            })}
                        </List>
                    </Grid>
                </Grid >)
        }
    }
}

export default DisplayRecipePage